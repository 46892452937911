<template>
  <div
    id="app"
    style="
      height: auto;
      min-height: 100vh;
      padding-bottom: 8px;
      background-color: #fff;
    "
  >
    <!-- 轮播 -->
    <van-swipe
    v-if="data"
      class="my-swipe"
      :autoplay="3000"
      indicator-color="white"
    >
      <van-swipe-item
        v-if="data.detailImageUrl"
        v-for="(item, index) in data.detailImageUrl"
        :key="index"
        ><img class="bannerImg" :src="item" alt=""
      /></van-swipe-item>
      <van-swipe-item v-if="!data.detailImageUrl"
        ><img
          class="bannerImg"
          src="../../assets/images/ytss_logo.jpg"
          alt=""
      /></van-swipe-item>
    </van-swipe>
    <!-- tabs -->
    <div class="TabContainer">
      <van-tabs v-model="active" @disabled="showUseTrace">
        <van-tab
          title="产品信息"
          v-if="data"
          :title-class="active == 1 ? 'right1' : ''"
        >
          <HomeProductComponent
          @getstageList="getstageList"
            :latitude="data.latitude"
            :longitude="data.longitude"
            :url="data.imageUrl"
            :mobile="data.mobile"
            :data="productData"
          ></HomeProductComponent>
        </van-tab>
        <van-tab
          title="全流程溯源"
          v-if="data"
          :title-class="active == 0 ? 'left1' : active == 2 ? 'right1' : ''"
          title-style="margin-left:-1px"
          :disabled="!isBatch"
        >
          <HomeProductTraceComponent
            :stage-list="data.stageList"
            :supplierAuth1="data.supplierAuth1 && data.supplierAuth1"
            :supplierAuth2="data.supplierAuth2 && data.supplierAuth2"
            :supplierAuth3="data.supplierAuth3 && data.supplierAuth3"
            :supplierName="data.supplierName && data.supplierName"
          ></HomeProductTraceComponent>
        </van-tab>
        <van-tab
          title="出品企业"
          v-if="data"
          :title-class="active == 1 ? 'left1' : active == 3 ? 'right1' : ''"
          title-style="margin-left:-1px"
        >
          <HomeBusinessInfoComponent
            :url="data.manufactureOrderContractUrl"
            :latitude="data.latitude"
            :longitude="data.longitude"
            :business="business"
            :supplierName="data.supplierName"
            :supplierContractId="data.supplierContractId"
            :businessFattenPushPopTime="data.businessFattenPushPopTime"
            :mobile="data.mobile"
          ></HomeBusinessInfoComponent>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 加载loading -->
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import HomeProductTraceComponent from "./HomeProductTraceComponent";
import HomeOriginInfoComponent from "./HomeOriginInfoComponent";
// import {Chart} from '@antv/g2';
import { Swipe, SwipeItem, Tab, Tabs, Toast, Loading } from "vant";
import { post } from "../../utils/http";
import { getDiffDay } from "../../utils/util";
import moment from "moment";
import HomeProductComponent from "./HomeProductComponent";
import HomeBusinessInfoComponent from "./HomeBusinessInfoComponent";
Vue.use(Swipe).use(SwipeItem).use(Tab).use(Tabs).use(Toast).use(Loading);
export default {
  name: "home",
  components: {
    HomeBusinessInfoComponent,
    HomeProductComponent,
    HomeProductTraceComponent,
    HomeOriginInfoComponent,
  },
  data() {
    return {
      // 是否有批次信息
      isBatch:false,
      active: 0,
      traceCode: "",
      data: null,
      totalStepNumber: 0,
      loadingAll: false,
      tabIndex: 0,
      chart: null, //图表对象
      showData: [
        //图表中将要显示的数据
      ],
      business: {
        title: "文昌鸡原产地简介",
        url: "",
        content:
          "安静的歌i加上见到过i哦啊手机导购i啊阿杰地阿佛第四届高is的架构as低结构i阿杰地宫颈癌搜地更煎熬is的警告是第几个",
        list: [{}, {}],
      },
      productData: {},
    };
  },

  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }

    this.traceCode = this.$route.query.traceCode;
    if (this.traceCode && this.traceCode !== "") {
      this.requestDataAll();
    }
    
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },

  methods: {
    goBack() {
      history.pushState(null, null, document.URL);
    },

    requestDataAll() {
      this.loadingAll = true;
      const url =
      "/api/public/v2/manufacture/tracing/{uniqueCode}/findProductById".replace(
          "{uniqueCode}",
          this.traceCode
        );
      post(url, {}, false)
        .then((res) => {
          this.data = res.data;
          this.business.itemList=this.data.supplierGlory
            this.business.latitude=this.data.latitude
            this.business.longitude=this.data.longitude
            this.business.introduction=this.data.supplierIntroduction
            this.business.address=this.data.address
            this.business.mobile=this.data.mobile
          if(this.data.detailImageUrl){
            this.data.detailImageUrl =
            this.data.detailImageUrl.split(";");

          }
          
          this.data.businessFattenPushPopTime = moment(
            this.data.supplierDigitalIdentityReportTime
          ).format("yyyy-MM-DD HH:mm:ss");

          if (
            !this.data.manufactureOrderContractId ||
            this.data.manufactureOrderContractId === ""
          ) {
            this.data.manufactureOrderContractId =
              "52f2275c2a758ea6b1e03ba45e9c5bb8020361fc8827b5f2bad7b5a64028deb086aa91ce8c3f7cc6ed15c29403ee9facf1a5ac1a93375ed86ce02ff73be0b232";
            this.data.manufactureOrderContractUrl =
              "https://asset.fsytss.com/srm-order/prod//2024/07/5ef55a41254248e48ebcc409ba73f230.jpg";
            this.data.manufactureOrderDigitalIdentityReportTime = 1720578927000;
          }

          this.productData = {
            productId:this.data.productId,
            businessId:this.data.businessId,
            address: this.data.address,
            imageUrl:this.data.imageUrl,
            fattenPushPopTime: this.data.fattenPushPopTime,
          
            formatFattenPushPopTime: moment(
              this.data.supplierDigitalIdentityReportTime
            ).format("yyyy-MM-DD HH:mm:ss"),
            productName: this.data.productName,
            scanNumber: this.data.scanNumber,
            breedingBatchCode: this.data.breedingBatchCode,
            supplierName: this.data.supplierName,
            productSpec: this.data.productSpec,
            manufactureOrderContractId: this.data.supplierContractId,
            exceedPopFarmDays:this.data.exceedPopFarmDays,
            exceedScanNumber:this.data.exceedScanNumber
          };
          let cycleDay = 0;
          let firstTrustList = [];
          if (this.data.supplierAuthChecked1 === 1) {
            firstTrustList.push({
              name: this.data.supplierAuth1,
            });
          }
          if (this.data.supplierAuthChecked2 === 1) {
            firstTrustList.push({
              name: this.data.supplierAuth2,
            });
          }
          if (this.data.supplierAuthChecked3 === 1) {
            firstTrustList.push({
              name: this.data.supplierAuth3,
            });
          }
          if (this.data.first) {
            this.totalStepNumber++;
          }
        
        
          console.log( this.productData);
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },

    getFeedingTime(feedTime) {
      return moment(feedTime).format("yyyy-MM-DD HH:mm:ss");
      // return moment(startTime).format('yyyy-MM-DD') + "至" + moment(endTime).format('yyyy-MM-DD')
    },
    getTime(startTime) {
      return moment(startTime).format("yyyy-MM-DD HH:mm:ss");
    },
    // 获取子组件的批次信息
    getstageList(getstageList){
      console.log("获取子组件的批次信息");
      
      if(getstageList){
        this.isBatch=true
        getstageList.forEach(item=>{
          if(item.receiptsTime||item.confirmTime){
            item.receiptsTime=this.getFeedingTime(item.receiptsTime)
            item.confirmTime=this.getFeedingTime(item.confirmTime)
          }
        })
        this.data.stageList=getstageList
      }else{
        this.isBatch=false
      }
     
      
    },
    showUseTrace(){
      console.log(this.isBatch);
      Toast('未查询批次信息或批次信息错误');
    }
  },
};
</script>

<style lang="less" scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  height: 224px;
  .bannerImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.TabContainer {
  width: 350px;
  margin: 0 auto;
  margin-top: 9px;
  // height: 50vh;
  // background-color: red!important;
  // background: url("../../assets/images/project1/projectInfoBackGround.png");
  background-color: rgb(238 242 193);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  ::v-deep.van-tabs__wrap {
    height: 32px;
  }
  ::v-deep.van-tab {
    background: url("../../assets/images/project4/tabs.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
    padding: 0;
  }

  ::v-deep.van-tab--active {
    background: url("../../assets/images/project4/pin2_select.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    font-weight: 500;
    font-size: 13px;
    color: #267438;
    // color: #ffffff;
  }
  ::v-deep.left1 {
    background: url("../../assets/images/project4/tableft.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
  }
  ::v-deep.right1 {
    background: url("../../assets/images/project4/tabright.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
  }
  ::v-deep.van-tabs__line {
    background-color: transparent !important;
  }

  
}
</style>