<template>
  <div>
    <div class="enterprise">
      <div class="enterpriseContainer">
        <div class="traceInfoContain">
          <div style="margin-top: 10px; text-align: -webkit-center">
            <div class="homeProductTraceIcon" />
            <div
              style="
                height: calc(100% - 4px - 6px);
                width: 0px;
                border-left: #5eb652 3px dotted;
                margin-top: 4px;
              "
            />
          </div>
          <div class="fromenterprise">
            <div class="baseinfo">出品企业</div>
            <div class="name">{{ supplierName }}</div>
            <div class="businessTagContainer">
              <div class="businessTag" v-if="supplierAuth1">
                <img
                  style="width: 19px; height: 19px; display: block"
                  src="../../assets/images/project4/businessTag.png"
                  alt=""
                />
                <div
                  style="
                    background-color: #f5f8ed;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    font-size: 9px;
                    color: #58b44c;
                    padding: 0px 4px;
                    display: flex;
                    align-items: center;
                    line-height: 9px;
                  "
                >
                  {{ supplierAuth1 }}
                </div>
              </div>
              <div class="businessTag" v-if="supplierAuth2">
                <img
                  style="width: 19px; height: 19px; display: block"
                  src="../../assets/images/project4/businessTag.png"
                  alt=""
                />
                <div
                  style="
                    background-color: #f5f8ed;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    font-size: 9px;
                    color: #58b44c;
                    padding: 0px 4px;
                    display: flex;
                    align-items: center;
                    line-height: 9px;
                  "
                >
                  {{ supplierAuth2 }}
                </div>
              </div>
              <div class="businessTag" v-if="supplierAuth3">
                <img
                  style="width: 19px; height: 19px; display: block"
                  src="../../assets/images/project4/businessTag.png"
                  alt=""
                />
                <div
                  style="
                    background-color: #f5f8ed;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    font-size: 9px;
                    color: #58b44c;
                    padding: 0px 4px;
                    display: flex;
                    align-items: center;
                    line-height: 9px;
                  "
                >
                  {{ supplierAuth3 }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 育苗阶段 -->
        <div class="traceInfoContain">
          <div style="margin-top: 12px; text-align: -webkit-center">
            <div style="margin-bottom: 4px" />
            <div class="homeProductTraceIcon" />
            <div
              style="
                height: calc(100% - 5px - 6px);
                width: 0;
                border-left: #5eb652 3px dotted;
                margin-top: 4px;
              "
            />
          </div>
          <div class="fromenterprise2">
            <div class="baseinfo">育苗阶段</div>
            <div class="infoleft">
              <div class="infoitem">
                <div class="key">养殖品种</div>
                <div class="value">{{ stageList[0].variety }}</div>
              </div>
              <div class="infoitem">
                <div class="key">
                  养
                  <div style="width: 6.5px" />
                  殖
                  <div style="width: 7px" />
                  户
                </div>
                <div class="value">
                  {{
                    stageList[0].farmer
                      ? stageList[0].farmer
                      : "个体户"
                  }}
                </div>
              </div>

              <div class="infoitem" v-if="stageList[0].baseName">
                <div class="key">养殖基地</div>
                <div class="value">{{ stageList[0].baseName }}</div>
              </div>
              <div class="infoitem" v-if="stageList[0].baseAddress">
                <div class="key">基地地址</div>
                <div class="value">{{ stageList[0].baseAddress }}</div>
              </div>
              <div class="infoitem" v-if="stageList[0].receiptsTime">
                <div class="key">养殖日期</div>
                <div class="value">{{stageList[0].receiptsTime }}</div>
              </div>
            </div>
            <div class="inforight">
              <div class="imagediv">
                <img
                  v-if="stageList[0].imageUrl"
                  :src="stageList[0].imageUrl"
                  alt=""
                />
                <img
                  v-if="!stageList[0].imageUrl"
                  src="../../assets/images/ytss_logo.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 加工阶段 -->
        <div class="traceInfoContain">
          <div style="margin-top: 12px; text-align: -webkit-center">
            <div style="margin-bottom: 4px" />
            <div class="homeProductTraceIcon" />
            <div
              style="
                height: calc(100% - 5px - 6px);
                width: 0;
                border-left: #5eb652 3px dotted;
                margin-top: 4px;
                opacity: 0;
              "
            />
          </div>
          <div class="fromenterprise2">
            <div class="baseinfo">加工阶段</div>
            <div class="infoleft">
              <div class="infoitem">
                <div class="key">商品名称</div>
                <div class="value">{{ stageList[1].productName }}</div>
              </div>

              <div class="infoitem" v-if="stageList[1].confirmTime">
                <div class="key">加工时间</div>
                <div class="value">{{ stageList[1].confirmTime }}</div>
              </div>
              <div class="infoitem" v-if="stageList[1].productionBatchNo">
                <div class="key">生产批次</div>
                <div class="value">{{ stageList[1].productionBatchNo }}</div>
              </div>
              <div class="infoitem" v-if="stageList[1].actualQty">
                <div class="key">产   <div style="width: 24px" /> 量</div>
                <div class="value">{{ stageList[1].actualQty }}</div>
              </div>
              <div class="infoitem" v-if="stageList[1].spec">
                <div class="key">规 <div style="width: 24px" /> 格</div>
                <div class="value">{{ stageList[1].spec }}</div>
              </div>
            </div>
            <div class="inforight">
              <div class="imagediv">
                <img
                  v-if="stageList[1].imageUrl"
                  :src="stageList[1].imageUrl"
                  alt=""
                />
                <img
                  v-if="!stageList[1].imageUrl"
                  src="../../assets/images/ytss_logo.jpg"
                  alt=""
                />
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部技术支持 -->
    <div class="bottom"> <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support.png" alt=""></div>

    <!-- <van-dialog v-model="show" confirm-button-text="关闭" confirm-button-color="#A1A1A1" >
      <div style="margin: 16px; font-weight: bold; text-align: center;">饲养记录</div>
      <div class="feedingItem">
        <div class="timedetailContainer">
            <div class="timedetail">饲养时间</div>
            <div>2024-05-13 18:00:35</div>
        </div>

      </div>
    </van-dialog> -->

    <van-dialog
      v-model="show"
      confirm-button-text="关闭"
      confirm-button-color="#A1A1A1"
    >
      <div style="margin: 16px; font-weight: bold; text-align: center">
        饲养记录
      </div>
      <div
        style="
          height: 50vh;
          overflow-y: scroll;
          padding: 0 8px;
          font-size: 14px;
        "
      >
        <van-list
          v-model="loading"
          :finished="true"
          v-if="feedingRecordList.length > 0"
        >
          <div
            style="display: flex; width: 100%"
            v-for="(item, index) in feedingRecordList"
            :key="index"
          >
            <div
              style="
                flex: 1;
                border-bottom: 0.5px solid rgb(229, 229, 229);
                padding: 8px;
                margin: 4px 0;
              "
            >
              <div style="display: flex; color: #a1a1a1">
                <div
                  class="bgc"
                  style="
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 13px;
                    color: #b54230;
                  "
                >
                  饲养日期
                </div>
                <div
                  style="
                    flex: 1;
                    color: #b54230;
                    font-weight: bold;
                    padding: 5px 0 5px 8px;
                    margin: auto 0;
                    text-align: right;
                  "
                >
                  {{ getFeedingTime(item.feedingTime) }}至{{ getFeedingTime(item.feedingEndTime) }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  color: #a1a1a1;
                  margin-top: 10px;
                  justify-content: space-between;
                "
              >
                <div style="width: 150px; padding-left: 8px">投喂饲料</div>
                <div
                  style="
                    color: #222222;
                    margin-left: 8px;
                    font-size: 13px;
                    color: #231815;
                    line-height: 20px;
                  "
                >
                  {{ item.feedingFood }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  color: #a1a1a1;
                  margin-top: 10px;
                "
              >
                <div style="padding-left: 8px">投喂频次</div>
                <div
                  style="
                    color: #222222;
                    margin-left: 8px;
                    font-size: 13px;
                    color: #231815;
                    line-height: 20px;
                  "
                >
                  {{ getFeedingFrequency(item.feedingFrequency) }}
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          description="暂未上传数据"
          style="height: 100%"
          v-if="feedingRecordList.length <= 0"
        />
      </div>
    </van-dialog>
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>

    <!-- 养殖场弹出 -->
    <van-dialog
      v-model="showFarm"
      confirm-button-text="关闭"
      confirm-button-color="#A1A1A1"
      style="background: #34ac40"
    >
      <div
        style="
          margin: 16px;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
        "
      >
        {{ farm ? farm.name : "" }}
      </div>
      <div
        style="
          height: 55vh;
          overflow-y: scroll;
          padding: 0 8px;
          font-size: 14px;
          background: linear-gradient(360deg, #f4f6f8 0%, #34ac40 100%);
        "
      >
        <div class="box" style="margin-top: 0">
          <div style="display: flex">
            <img
              src="../../assets/images/bias-double-dot.png"
              style="width: 18px; height: 18px"
            />
            <div style="line-height: 18px; margin-left: 5px; font-weight: bold">
              基础信息
            </div>
          </div>

          <div style="flex: 1; color: #a1a1a1">
            <div style="display: flex; margin-top: 4px">
              养殖容量
              <div
                style="
                  flex: 1;
                  color: #222222;
                  margin-left: 8px;
                  text-align: right;
                "
              >
                {{ farm.volume }}
              </div>
            </div>
            <div style="display: flex; margin-top: 4px">
              圈
              <div style="width: 7px" />
              舍
              <div style="width: 7px" />
              数
              <div
                style="
                  flex: 1;
                  color: #222222;
                  margin-left: 8px;
                  text-align: right;
                "
              >
                {{ farm.circleHouse }}个
              </div>
            </div>
            <div style="display: flex; margin-top: 4px">
              面积(m²)
              <div
                style="
                  flex: 1;
                  color: #222222;
                  margin-left: 8px;
                  text-align: right;
                "
              >
                {{ farm.acreage }}
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div style="color: #222222; font-weight: bold; margin-top: 8px">
            <div style="display: flex">
              <img
              src="../../assets/images/bias-double-dot.png"
                style="width: 18px; height: 18px"
              />
              <div
                style="line-height: 18px; margin-left: 5px; font-weight: bold"
              >
                养殖场简介
              </div>
            </div>
            <van-image
              width="100%"
              height="22vh"
              :src="farm ? farm.imageUrl : ''"
              style="margin-top: 8px"
            />
            <div
              style="
                color: #222222;
                font-size: 14px;
                margin-top: 8px;
                font-weight: normal;
              "
            >
              {{ farm.introduction }}
            </div>
          </div>
        </div>
        <div class="box">
          <div
            style="
              color: #222222;
              font-weight: bold;
              margin-top: 8px;
              margin-bottom: 12px;
            "
          >
            <div style="display: flex">
              <img
              src="../../assets/images/bias-double-dot.png"
                style="width: 18px; height: 18px"
              />
              <div
                style="line-height: 18px; margin-left: 5px; font-weight: bold"
              >
                定位信息
              </div>
            </div>
            <div
              id="farmMap"
              style="width: 100%; height: calc(320px / 16 * 9); margin-top: 8px"
            ></div>

            <div
              style="
                display: flex;
                color: #222222;
                font-weight: normal;
                margin-top: 8px;
              "
            >
              <div style="width: 42px">{{ "地址:" }}</div>
              <div style="flex: 1">{{ farm.address }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { Image as VanImage, Dialog, Empty, List, Loading } from "vant";
import { post } from "../../utils/http";
import moment from "moment";
Vue.use(VanImage).use(Dialog).use(Empty).use(List).use(Loading);
export default {
  name: "HomeProductTraceComponent",
  props: [
    "stageList",
    "supplierAuth1",
    "supplierAuth2",
    "supplierAuth3",
    "supplierName",
  ],
  data() {
    return {
      show: false,
      feedingRecordList: [],
      loadingAll: false,
      farm: {},
      showFarm: false,
    };
  },
  methods: {
    showDialog(stageId) {
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{stageId}/stageItemInfo".replace(
          "{stageId}",
          stageId
        );
      post(url, {}, false)
        .then((res) => {
          this.feedingRecordList = res.data.breedingBatchStageFeedingList;

          this.showFeedingRecord = true;
          this.show = true;
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
   
    onClickFeedingRecord(item) {
      this.$parent.onClickShowFeedingRecord(item.id);
    },
    onClickVaccinumRecord(item) {
      this.$parent.onClickShowVaccinumRecord(item.id);
    },
    onClickFarm(item) {
      console.log(item);
      if (!item.farmManagerId) {
        return;
      }
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{farmManagerId}/farmManagerInfo".replace(
          "{farmManagerId}",
          item.farmManagerId
        );
      post(url, {}, false)
        .then((res) => {
          // this.vaccinumRecordList = res.data.breedingBatchStageVaccineList;

          this.farm = res.data;

          this.showFarm = true;

          setTimeout(() => {
            this.initFarmMap(); // 渲染地图
          }, 500);
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    initFarmMap() {
      let latitude = Number(this.farm.latitude);
      let longitude = Number(this.farm.longitude);

      console.log("initFarmMap = " + latitude + " ; " + longitude);

      if (this.farmMap) {
        this.farmMap.setCenter(new TMap.LatLng(latitude, longitude));
        this.farmMarkerLayer.updateGeometries([
          {
            styleId: "myStyle",
            id: "1",
            position: new TMap.LatLng(latitude, longitude),
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ]);

        return;
      }

      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.farmMap = new TMap.Map("farmMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });

      //创建并初始化MultiMarker
      this.farmMarkerLayer = new TMap.MultiMarker({
        map: this.farmMap, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },

    getFeedingTime(feedTime) {
      return moment(feedTime).format("yyyy-MM-DD");
      // return moment(startTime).format('yyyy-MM-DD') + "至" + moment(endTime).format('yyyy-MM-DD')
    },
    getFeedingFrequency(feedingFrequency) {
      if (feedingFrequency) {
        switch (feedingFrequency) {
          case "EVERYDAY":
            return "每日投喂";
          case "THREE_DAYS":
            return "三日一次";
          case "TWO_DAYS":
            return "两日一次";
          case "SINGLE_TIME":
            return "单次投喂";
          default:
            return "";
        }
      }
      return "";
    },
  },
  mounted() {
    console.log(this.stageList);
  },
};
</script>
  
  <style lang="less" scoped>
.enterprise {
  display: flex;
}
.enterpriseContainer {
  // display: flex;
  width: 100%;
}
.left {
  width: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.traceInfoContain {
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
}
.code {
  width: 100%;
}
.line {
  width: 5px;
}
.fromenterprise {
  flex: 1;
  margin-top: 27px;
  margin-left: 12px;
  // margin-left: 13px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  padding-top: 29px;
  padding-bottom: 12px;
}
.baseinfo {
  position: absolute;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    
    rgb(59 173 54),
    rgb(181 210 62)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: -11px;
}
.name {
  padding: 0 16px;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  color: #231815;
  text-align: center;
}
.fromenterprise2 {
  flex: 1;
  margin-top: 27px;
  margin-left: 12px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  padding-top: 16px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.infoleft {
  width: 160px;
  padding-left: 10px;
  padding-right: 15px;
}
.inforight {
  // width: 125px;
  margin-top: -16px;
  border-top-right-radius: 8px;
  overflow: hidden;

  .imagediv{
    border-bottom-left-radius: 8px;
    img {
      width: 125px;
      height: 161px;
      object-fit: cover;
    }
  }
}
.infoitem {
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  margin-bottom: 3px;

}
.key {
  font-size: 12px;
  color: #666666;
  width: 64px;
  display: flex;
  
}
.value {
  font-size: 12px;
  color: #231815;
  width: 94px;
  overflow: hidden; /* 隐藏超出的文本 */
display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
-webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
word-break: break-all; /* 允许在单词内换行 */
margin-left: 14px;
}
.status {
  display: block;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 16px;
  width: 95px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 12px;
  font-size: 13px;
  line-height: 14px;
  color: #fff;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}

.bgc {
  background: url("../../assets/images/project1/feedingTimeBack.png") no-repeat
    center / cover;
  background-size: 100% 100%; /* 拉伸背景图片以填充整个盒子 */
  padding: 0 8px;
}

.businessTagContainer {
  // text-align: center;

  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  .businessTag {
    display: flex;
    justify-content: center;
    margin-right: 6px;
  }
}
.homeProductTraceIcon {
  height: 15px;
  width: 15px;
  background: url(../../assets/images/project4/code.png) no-repeat;
  background-size: 15px;
  margin-top: 10px;
}
.box {
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  margin: 10px 0;

  & + .box {
    margin-top: 16px;
  }

  .title {
    padding-left: 25px;
    position: relative;
    margin-bottom: 8px;

    &::after,
    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      background: #64b65b;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 2;
    }

    &::after {
      background: #cce8cb;
      z-index: 1;
      left: 4px;
      top: 1px;
    }
  }
}
</style>